@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");

/* Existing button-86 styles */
.button-86 {
  all: unset;
  width: 178px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: "Poppins", sans-serif;
}

.button-86::after,
.button-86::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: linear-gradient(
    60deg,
    rgba(0, 123, 255, 0.8) 0%,
    rgba(0, 86, 179, 0.8) 100%,
    rgba(0, 48, 128, 0.8) 100%
  );
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

/* New button-76 with orange color scheme */
.button-76 {
  all: unset;
  width: 178px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: "Poppins", sans-serif;
}

.button-76::after,
.button-76::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.button-76::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: linear-gradient(
    60deg,
    rgba(255, 165, 0, 0.8) 0%,    /* Light orange */
    rgba(255, 140, 0, 0.8) 100%,    /* Darker orange */
    rgba(255, 69, 0, 0.8) 100%      /* Even darker orange */
  );
  border-radius: 10px;
}

.button-76::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-76:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-76:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-76:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
