.prize-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    border-radius: 0.75rem;
    transition: transform 0.3s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    width: 100%;
    height: 100%;
    max-width: none;
  }
  
  .prize-card:hover {
    transform: scale(1.05);
  }
  
  .prize-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .prize-content {
    text-align: center;
  }
  
  .prize-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .prize-description {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
  }
  