.projects-page {
    padding: 160px 20px; /* Add padding to create space from the navbar */
    background-color: #000; /* Set background to black */
    color: #fff; /* Ensure text color is white for better contrast */
  }
  
  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #222; /* Dark background for filters section */
    padding: 10px;
    border-radius: 8px;
  }
  
  .filters label {
    margin-right: 10px;
    color: #fff; /* Ensure label text is white */
  }
  
  .filters select {
    padding: 8px; /* Match the padding with search input */
    font-size: 14px; /* Match font size with search input */
    background-color: #333; /* Dark background to match search input */
    color: #fff; /* White text color for consistency */
    border: 1px solid #444; /* Match the border color with search input */
    border-radius: 5px; /* Match the border radius with search input */
  }
  
  .filters select option {
    background-color: #333; /* Dark background for select options */
    color: #fff; /* White text color for options */
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .project-card {
    background-color: #333; /* Dark background for the project card */
    padding: 20px;
    height: 60vh; /* Default height for larger screens */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between;
  }
  
  @media (orientation: portrait) {
    .project-card {
      height: auto; /* Adjust height */
      height: 25vh; /* Full viewport width */
    }
  }

  .project-id {
    font-size: 12px;
    color: #bbb; /* Light color for the project ID */
  }
  
  .project-title {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .project-admin {
    font-size: 14px;
    color: #ddd; /* Light color for admin text */
    margin-bottom: 10px;
  }
  
  .project-tags {
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  .project-tags .tag {
    background-color: #555; /* Darker tag background */
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    color: #fff; /* White text color for tags */
  }
  
  .description-button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;   
  }
  
  .description-button:hover {
    background-color: #0056b3;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 10%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #222; /* Darker background for popup */
    padding: 30px;
    border-radius: 10px;
    max-width: 1200px;
    width: 100%;
    position: relative;
    color: #fff; /* White text color for popup */
    }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #fff; /* White color for close button */
    cursor: pointer;
  }
  
  .popup-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px 0;
  }
  
  .popup-tags .tag {
    background-color: #555; /* Darker tag background */
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    color: #fff; /* White text color for tags */
  }
  
  /* Blinking cursor */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.search-filter {
  display: flex;
  align-items: center; /* Vertically center the label and input */
  margin-bottom: 0; /* Align with other filters */
}

#search-input {
  width: 100%;
  max-width: 300px; /* Set a max-width for the input field */
  padding: 8px;
  font-size: 14px; /* Adjust font size to match the dropdown */
  border: 1px solid #444; /* Match the border color with dropdowns */
  border-radius: 5px; /* Match the border radius with dropdowns */
  outline: none;
  color: #fff; /* White text color for consistency */
  background-color: #333; /* Dark background to match dropdowns */
}

#search-input::placeholder {
  color: #aaa;
}

#search-input:focus {
  border-color: #007bff;
}

#search-input:focus::after {
  content: "|";
  animation: blink 1s infinite;
}

input[type="text"] {
  color: #333; /* Ensure text color is visible */
}
