@media only screen and (min-width: 768px) {
  video {
    transform: translateX(1px);
    height: 700px;
  }
}
 
/* @media only screen and (min-width: 1024px) {
  video {
    transform: translateX(-100px);
    height: 700px;
  }
} */

@media only screen and (min-width: 1080px) {
  video {
    transform: translateX(1px);
    height: 700px;
  }
}

@media only screen and (min-width: 1036px) {
  video {
    transform: translateX(1px);
    height: 700px;
  }
}

@media only screen and (min-width: 1025px) {
  video {
    transform: translateX(1px);
    height: 700px;
  }
}

@media only screen and (min-width: 1024px) {
  video {
    transform: translateX(1px);
    height: 700px;
  }
}


@media only screen and (min-width: 1280px) {
  video {
    transform: none;
    height: 700px;
    width: 1700px;
  }
}

@media only screen and (min-width: 1038px) {
  video {
    transform: none;
    height: 700px;
    width: 2000px;
  }
}

@media only screen and (min-width: 1536px) {
  video {
    transform: none;
    height: 600px;
    width: 1700px;
  }
}

@media only screen and (min-width: 1903px) {
  video {
    transform: none;
    height: 1000px;
    width: 2200px;
  }
}


/* @media only screen and (min-width: 768px) and (max-width: 1068px) {
  video {
    height: 876px;
    transform: translateX(-133px);
  }
} */

/* Media query for screens 1172px and above */
@media (min-width: 1172px){
  .absolute {
    top: 32rem; /* Adjust the value based on your design */
  }
}

@media (min-width: 1154px) and (min-width: 1165px){
  .absolute {
    top: 33rem; /* Adjust the value based on your design */
  }
}


@media (min-width: 1300px) {
  .absolute {
    top: 34rem; /* Adjust the value based on your design */
  }
}

@media (min-width: 1350px) {
  .absolute {
    top: 36rem; /* Adjust the value based on your design */
  }
}


@media (min-width: 1400px) {
  .absolute {
    top: 40rem; /* Adjust the value based on your design */
  }
}

@media (min-width: 1600px) {
  .absolute {
    top: 12rem; /* Adjust the value based on your design */
  }
}

@media (min-width: 1840px) {
  .absolute {
    top: 64rem; /* Adjust the value based on your design */
  }
}

@media (min-width: 1900px) {
  .absolute {
    top: 80rem; /* Adjust the value based on your design */
  }
}

@media (min-width: 1600px) {
  .absolute {
    top: 45rem; /* Adjust the value based on your design */
  }
}