#schedule {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.pathSelectors button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 24px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.pathSelectors button span {
  z-index: 1;
}

.pathSelectors button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.pathSelectors button:hover {
  color: #121212;
}

.pathSelectors button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.keyDatesCards {
  border-radius: 10px;
  background: #1b1c27; /* Adjusted background color */
  box-shadow: inset 0px -4px 10px rgba(66, 133, 244, 0.7); /* Changed box shadow color to blue */
  display: block;
  width: 520px; /* Reduced width */
  height: 285px; /* Reduced height */
  transition: box-shadow 0.3s ease; /* Adding transition to box-shadow */
  cursor: pointer;
}

.keyDatesCards:hover {
  box-shadow: inset 0px -4px 10px rgba(66, 133, 244, 0.7),
    0px 0px 10px rgba(66, 133, 244, 0.5); /* Adding hover shadow effect with blue color */
}

@media only screen and (max-width: 600px) {
  .schedule {
    height: inherit;
  }

  .pathButton1,
  .pathButton2 {
    font-size: 1.2rem;
  }
}
