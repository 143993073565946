.scroll {
  align-items: center;
  -webkit-animation: bounce 4s infinite;
  animation: bounce 4s infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  top: 18rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: center;
  position: absolute;
  right: 2.5rem;
  -webkit-transform-origin: right top 0;
  transform-origin: right top 0;
}

.scroll a {
  text-decoration: none;
  background: transparent;
  color: #fff;
  display: inline-block;
  font-size: 0.9rem;
  letter-spacing: 0.3rem;
  margin-bottom: 0.6rem;
  position: relative;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
}
