/* BAND COLORS */

.blue-cyan {
  background: linear-gradient(98.84deg, #8680bb 43.27%, #84b4b5 71.84%);
}

.blue-cyan-text {
  background: linear-gradient(98.84deg, #8680bb 43.27%, #84b4b5 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.purple-pink {
  background: linear-gradient(98.84deg, #a8519f 43.27%, #644ea0 71.84%);
}

.purple-pink-text {
  background: linear-gradient(98.84deg, #a8519f 43.27%, #644ea0 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.green-yellow {
  background: linear-gradient(98.84deg, #80ffea 43.27%, #8aff81 71.84%);
}

.green-yellow-text {
  background: linear-gradient(98.84deg, #80ffea 43.27%, #8aff81 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.orange-pink {
  background: linear-gradient(98.84deg, #ffff80 43.27%, #ff82bf 71.84%);
}

.orange-pink-text {
  background: linear-gradient(98.84deg, #ffff80 43.27%, #ff82bf 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.red-orange {
  background: linear-gradient(98.84deg, #ff6f61 43.27%, #ff8c42 71.84%);
}

.red-orange-text {
  background: linear-gradient(98.84deg, #ff6f61 43.27%, #ff8c42 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.blue-purple {
  background: linear-gradient(98.84deg, #7b42f6 43.27%, #b642f6 71.84%);
}

.blue-purple-text {
  background: linear-gradient(98.84deg, #7b42f6 43.27%, #b642f6 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.yellow-green {
  background: linear-gradient(98.84deg, #f9f871 43.27%, #a8eb12 71.84%);
}

.yellow-green-text {
  background: linear-gradient(98.84deg, #f9f871 43.27%, #a8eb12 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.pink-red {
  background: linear-gradient(98.84deg, #ff9ff3 43.27%, #ff6b6b 71.84%);
}

.pink-red-text {
  background: linear-gradient(98.84deg, #ff9ff3 43.27%, #ff6b6b 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.green-blue {
  background: linear-gradient(98.84deg, #00c6ff 43.27%, #0072ff 71.84%);
}

.green-blue-text {
  background: linear-gradient(98.84deg, #00c6ff 43.27%, #0072ff 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.purple-red {
  background: linear-gradient(98.84deg, #9d50bb 43.27%, #6e48aa 71.84%);
}

.purple-red-text {
  background: linear-gradient(98.84deg, #9d50bb 43.27%, #6e48aa 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.teal-lime {
  background: linear-gradient(98.84deg, #64e8e5 43.27%, #b6f493 71.84%);
}

.teal-lime-text {
  background: linear-gradient(98.84deg, #64e8e5 43.27%, #b6f493 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.orange-yellow {
  background: linear-gradient(98.84deg, #ffb347 43.27%, #ffcc33 71.84%);
}

.orange-yellow-text {
  background: linear-gradient(98.84deg, #ffb347 43.27%, #ffcc33 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.magenta-violet {
  background: linear-gradient(98.84deg, #ff00ff 43.27%, #8a2be2 71.84%);
}

.magenta-violet-text {
  background: linear-gradient(98.84deg, #ff00ff 43.27%, #8a2be2 71.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.cards-grid {
  height: auto;
  width: auto;
  padding: 1rem;
  gap: 20px;
}

@media only screen and (max-width: 768px) {
  .clg-desc {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .about-us-h2 {
    text-align: center;
    margin: 10px auto 10px auto;
  }
  .about-us-p {
    text-align: justify;
  }
  .clg-map h2 {
    text-align: center;
    margin: 10px auto 10px auto;
  }
}

.CommunityImage {
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 0.3s;
  cursor: pointer;
}

.CommunityImage:hover {
  transform: scale(1.05);
  box-shadow: rgb(41, 112, 198) 0px 4px 10px;
}
