html {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: transparent;
  width: 100%;
  top: 110px;
  left: 0;
  border-radius: 40px;
}

#progressBar {
  background: linear-gradient(to left, #367caa, #7fd6d6b3);
  transform-origin: top left;
  transform: scale(0, 0);
  border-radius: 40px;

  /* opacity: 0; */
}

#progressBarContainer,
#progressBar {
  height: 7px;
  border-radius: 40px;
}
